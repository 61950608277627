.body {
  background-color: #363536;
}

.columns_div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.order_div {
  max-width: 250px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  min-height: 300px;
  padding: 0 5px 10px 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #585858;
  border-radius: 2px;
  background-color: transparent;
}

.time_text {
  margin-top: 10px;
  padding-top: 0px;
  padding-left: 10px;
  font-family: Lato, sans-serif;
  color: #f7f3f3;
  text-align: left;
}

.itemtitle {
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 28px;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  text-transform: none;
}

.subitem_text {

  font-family: Lato, sans-serif;
  color: #b6b5b5;
  text-align: center;
  font-size: 22px;
  line-height: 25px;
}

.red{
  color: red;
}

.header {
  margin-top: 20px;
}
